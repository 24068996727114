import { Link } from "gatsby";

import PropTypes from "prop-types";
import React from "react";
import "./goals.scss";
import  Countdown  from "../components/Countdown"
import { GrMapLocation } from "@react-icons/all-files/gr/GrMapLocation";


const Goals = ({ siteTitle }) => (

  
  <section className="goals" style={{marginTop: `25px`}}>
    <article>
      <h2>Pennine Rally 500km <span className="done"> - DONE! &#x1F389;</span></h2>
      <h4>15-19th June 2021</h4>
      <p><GrMapLocation/> <a href="https://www.google.com/maps/place/Pentland+Hills">Edinburgh</a></p>

      <p>This is a 500km ride from Edinburgh to Manchester, 50% of which is off-road. I'm thinking of doing 300km+ in one day to satisfy my 300km+ in one go goal but I'm not sure how to make sure I get to all 3 controls doing that. We'll see. If not I'll smash out a 300km+ audax at some point to satisfy this one. Big achievement either way!</p>

      <p><Countdown date={`2021-06-15`}/></p>

      <a href="https://www.rapha.cc/gb/en/stories/the-pennine-rally">More info</a>
    </article>
    
    <article>
      <h2>Mt. Ventoux with Rapha Manchester</h2>
      <h4>28th September 2021</h4>
      <p><GrMapLocation/> <a href="https://veloviewer.com/segments/2343734">Col du Mont Ventoux (Bédoin)</a></p>

      <p>4 days in Southern France with my clubmates. One of which involves going up a big chuffin' hill.</p>

      <p><Countdown date={`2021-09-28`}/></p>

    </article>

    <article>
      <h2>Operation: don't come last at the National Hill Climb Championships</h2>
      <h4>31th October 2021</h4>
      <p><GrMapLocation/> <a href="https://veloviewer.com/segments/11552362">Winnats Pass</a></p>

      <p>God I'm shite at climbs. My goal for this is genuinely: don't come last.</p>

      <p><Countdown date={`2021-10-31`}/></p>

      <a href="https://twitter.com/winnats21">More info</a>
    </article>

    <article>
      <h2>Enter my first TT race</h2>
      <h4>TBC</h4>
      <p><GrMapLocation/> <a href="#">TBC</a></p>

      <p>Never done a race nevermind a TT but I think a flat TT will suit me well.</p>

      {/* <Countdown date={`2021-10-31`}/></p> */}

      {/* <p><a href="https://twitter.com/winnats21">More info</a> */}
    </article>

    <article>
      <h2>Enter my first fixed gear crit race</h2>
      <h4>TBC</h4>
      <p><GrMapLocation/> <a href="#">TBC</a></p>

      <p>Fixed gear crits look scary af but also fun af. I've ridden fixed gears loads over the years but never in this context (and without brakes!)</p>

      {/* <p><Countdown date={`2021-10-31`}/></p> */}

      {/* <a href="https://twitter.com/winnats21">More info</a> */}
    </article>


  </section>

)

Goals.propTypes = {
siteTitle: PropTypes.string,
}

Goals.defaultProps = {
    siteTitle: ``,
}

export default Goals
